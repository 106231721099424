import React, { FC, useEffect, useState } from 'react';
import Image from 'next/image';
import CardsFallBackImage from '../CardsFallBackImage/CardsFallBackImage';
import classnames from 'classnames';
import styles from './index.module.scss';

interface CardsCompanyLogoProps {
  title: string;
  id: number;
  href: string;
  width?: number;
  height?: number;
  objectFit?: 'contain' | 'cover';
  imageType?: 'logo' | 'full';
  // How do we know what type of logo as they change per grid type 4x3/16x9
  gridType?: 'webcasts' | 'summits' | 'series' | 'channels';
}

const CardsCompanyLogo: FC<CardsCompanyLogoProps> = ({ title, id, href, width, height, objectFit, gridType, imageType }) => {
  const [imgSrc, setImgSrc] = useState(href);

  useEffect(() => {
    setImgSrc(href);
  }, [href]);

  let fallbackSrc;
  if ( gridType === 'channels' ) {
    fallbackSrc = '/portal/placeholders/brand-logo.svg';
  } else {
    fallbackSrc = '/portal/placeholders/diamond-cube.svg';
  }

  return (
    <>

      {href ? (
        <Image
          className={classnames( styles[gridType], styles['company-logo'], styles['card-image-el'], styles[imageType] ) }
          key={`company-logo-` + id}
          src={imgSrc ? imgSrc : fallbackSrc }
          width={width}
          height={height}
          //object-fit={objectFit}
          blurDataURL={href}
          alt={title}
        //   layout="fill"
          placeholder="blur"
          onError={() => {
           setImgSrc( fallbackSrc );
          }}
        />
      ) : (
        <CardsFallBackImage gridType={ gridType } imageType={'logo'} width={width} height={height} title={title} />
      )}
    </>
  );
};

export default CardsCompanyLogo;
